import { graphql, PageProps } from "gatsby";
import React from "react";
import GAScripts from "../components/GAScripts";
import Heading from "../components/ui/Heading";
import Layout from "../components/ui/Layout";
import * as classes from "./about.module.scss";

const aboutPage = () => (
  <Layout>
    <section>
      <Heading>About Us</Heading>
      <div className={classes["body"]}>
        <p>
          Our coalition of New College of Florida Students, Alums and Allies
          quickly sprang into action and joined forces to defend educational
          freedom amid a hostile takeover of the college’s new Board of Trustees
          appointed by Governor Ron DeSantis.
        </p>
        <p>
          We want to keep politicians out of the classroom so we can continue to
          be a free-thinking, welcoming institution where learning happens
          without government interference.
        </p>
      </div>
    </section>

    <section>
      <Heading>Mission Statement</Heading>
      <div className={classes["body"]}>
        <p>
          We are joining together to protect New College’s history and values,
          and to launch a call to defend the educational freedom of every
          college student, giving them a safe space to learn, think, and be who
          they want without government interference in their classroom.
        </p>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query AboutPage {
    siteBuildMetadata {
      buildTime
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Head = ({ data }: PageProps<Queries.AboutPageQuery>) => (
  <>
    <title>{`${data.site?.siteMetadata?.title} - About Us`}</title>
    <meta name="description" content="Save New College" />
    <GAScripts />
  </>
);

export default aboutPage;
